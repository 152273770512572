import { defineStore } from 'pinia'

export const useStore = defineStore('main', {
  state: () => ({
    indexPage: true,
    sectionArrs: []
  }),
  getters: {
    // getters用来处理数据，对state中的数据进行处理，得到自己想要的效果，当需要在多处组件使用这种数据时，gettters是你最好的选择
    // double: (state) => state.count * 2,
  },
  actions: {
    // actions当你的数据是需要发送请求获取时，这是非常完美的选择
    // increment() { this.count++ },
  }
})