import { createRouter, createWebHistory } from "vue-router"
//createWebHashHistory 哈希模式 
//createWebHistory 历史模式

// 3. 创建路由实例并传递 `routes` 配置
export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: "首页",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/index.vue')
        },
        {
            path: '/case',
            name: "案例列表",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/case.vue')
        },
        {
            path: '/case_details',
            name: "案例详情",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/case_details.vue')
        },
        // 404页面
        {
            path: '/404',
            name: "404",
            meta: {
                keepAlive: false
            },
            component: () => import('@/views/error/404.vue')
        },
        { path: "/:pathMatch(.*)", redirect: "/404" }
    ]
})

export default router