<template>
  <header class="header">
    <div class="box">
      <img class="logo" src="@/assets/img/logo_02.png" @click="$openFrame('/')" />
      <div class="flex-grow"></div>
      <el-scrollbar view-class="wc-menu">
        <span :class="activeIndex == 0 ? 'active' : ''" @click="handleSelect(0)">首页</span>
        <span :class="activeIndex == 1 ? 'active' : ''" @click="handleSelect(1)">关于我们</span>
        <span :class="activeIndex == 2 ? 'active' : ''" @click="handleSelect(2)">业务介绍</span>
        <span :class="activeIndex == 3 ? 'active' : ''" @click="handleSelect(3)">研发优势</span>
        <span :class="activeIndex == 4 ? 'active' : ''" @click="handleSelect(4)">精彩案例</span>
        <span :class="activeIndex == 5 ? 'active' : ''" @click="handleSelect(5)">业务伙伴</span>
        <span :class="activeIndex == 6 ? 'active' : ''" @click="handleSelect(6)">联系我们</span>
      </el-scrollbar>     
      <el-button class="menu-btn" @click="dialogVisible = true">登录</el-button>
    </div>
  </header>

  <el-dialog v-model="dialogVisible" title="瑞思平台列表" :show-close="false">
    <ul class="dialogul">
      <li @click="goLink('http://mall.reseeol.com')">
        <img src="@/assets/img/logo_03.png" alt="">
        <p>瑞思管理平台(商城版)</p>
      </li>
      <li @click="goLink('http://shop.reseeol.com')">
        <img src="@/assets/img/logo_04.png" alt="">
        <p>瑞思管理系统(单店版)</p>
      </li>
    </ul>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
const emit = defineEmits(['indexChang'])
const dialogWidth = ref()

defineProps({
  activeIndex: {
    type: Number,
    default: 0
  }
})

const dialogVisible = ref(false)

// 导航点击事件
const handleSelect = (item) => {
  emit('indexChang', item)
}

const goLink = (val) => {
  window.open(val)
}
</script>

<style>
.wc-menu {
  display: flex;
  white-space: nowrap;
}
</style>

<style scoped>
.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.box {
  height: 60px;
  display: flex;
  align-items: center;
}

.logo {
  width: 120px;
  height: 60px;
  object-fit: contain;
  cursor: pointer;
}

.wc-menu span {
  display: block;
  height: 60px;
  line-height: 60px;
  padding: 0 10px;
  cursor: pointer;
  box-sizing: border-box;
}

.wc-menu .active {
  border-bottom: 4px solid;
  color: #d02543;
}

.flex-grow {
  flex-grow: 1;
}

.menu-btn {
  margin: 0 10px;
}

.dialogul {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 800px) and (min-width: 300px) {
  .dialogul {
    display: block;
  }
}

.dialogul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
}

.dialogul li:hover,
.dialogul li:active {
  background-color: rgba(18, 42, 136, 0.1);
}

.dialogul img {
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.dialogul p {
  color: var(--el-text-color-primary);
  font-size: 16px;
  padding: 10px 0;
}
</style>
