<template>
  <footer class="footer">
    <ul class="footer_cented">
      <li>
        <el-icon size="30">
          <DeleteLocation />
        </el-icon>&nbsp;
        江西省萍乡市蓝天大厦1113室
      </li>
      <li>
        <el-icon size="30">
          <Iphone />
        </el-icon>&nbsp;
        15907990117 何经理
      </li>
    </ul>
    <p class="tips">© {{ year }} Resee All Rights Reserved.
      <a href="http://beian.miit.gov.cn" target="_blank">赣ICP备2023000995号</a>
    </p>
  </footer>
</template>

<script setup>
import { getCurrentInstance, ref } from 'vue'
const { proxy } = getCurrentInstance()
const year = ref(proxy.$getCurrentDate('YY'))
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  padding: 20px 0;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.footer_cented {
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tips {
  text-align: center;
  font-size: 10px;
}



@media screen and (max-width: 800px) and (min-width: 300px) {
  .footer_cented {
    display: block;
  }

  .footer {
    font-size: 14px;
  }
}
</style>
