import { createApp } from 'vue'
import App from './App.vue'

import './assets/css/normalize.css' // 公共样式
import ElementPlus from 'element-plus' // element-ui插件
import 'element-plus/dist/index.css'  // element-ui插件样式
import zhCn from 'element-plus/dist/locale/zh-cn.mjs' // element-ui语言
import * as ElementPlusIconsVue from '@element-plus/icons-vue' // element-ui 图标
import { createPinia } from 'pinia'
const pinia = createPinia() // 状态管理
import router from './router/index' // 路由管理
import Util from './libs/util' // 全局工具函
import vue3SeamlessScroll from 'vue3-seamless-scroll' // 无缝滚动插件

const app = createApp(App)
app.use(pinia) // 注册pinia组件 
app.use(router)// 注册router组件 
app.use(ElementPlus, { locale: zhCn })// 注册element-ui组件 vue官方库支持new Vue()
app.use(ElementPlusIconsVue)// 注册element-ui图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}  // 注册element-ui图标方法
app.use(vue3SeamlessScroll)  // 注册全局无缝滚动插件
app.use(Util) // 注册全局工具函数组件
app.mount('#app')
