<template>
  <web-header :activeIndex="tabsIndex" @indexChang="tabsChang" />

  <el-scrollbar ref="elscrollbar" :height="scrollbarHeight" @scroll="elscroll">
    <router-view></router-view>
    <web-footer />
    <div class="sticky">
      <el-button v-if="isTopBtn" class="top" @click="scrollToHeader">
        <el-icon>
          <Top />
        </el-icon>
      </el-button>
    </div>
  </el-scrollbar>
</template>

<script setup>
/** 引入 */
import { onBeforeMount, onUpdated, onMounted, onUnmounted, ref, getCurrentInstance } from 'vue'
import { useStore } from '@/store/index'
import webHeader from '@/components/header.vue'
import webFooter from '@/components/footer.vue'
const store = useStore()
const { proxy } = getCurrentInstance()

// 导航序
const tabsIndex = ref(0)
// 滚动条组件
const elscrollbar = ref(null)
// 滚动条高度
const scrollbarHeight = ref(0)
// 是否监测滚动
const isScroll = ref(true)
const isTopBtn = ref(false)
// 本地参数
var timer = null
/**
 * 组件被挂载前
 */
onBeforeMount(() => {
  scrollbarHeight.value = window.innerHeight - 60
})

/**
 * 组件挂载完成后
 */
onMounted(() => {
  // 将返回顶部方法挂载全局
  window.scrollToHeader = scrollToHeader
  window.setTabsIndex = setTabsIndex
})

/**
 * 组件响应式改动
 */
onUpdated(() => { })

/**
 * 组件实例被卸载之后
 */
onUnmounted(() => { })

// 设置当前tabs标签
function setTabsIndex() {
  tabsIndex.value = 0
}
// 滚动事件监测
function elscroll(val) {
  let sT = val['scrollTop']
  proxy.$scrollAnimate(sT)

  let arr = sectionScrollHeight()

  // 是首页时的滚动事件监听
  if (store['indexPage'] && isScroll.value) {
    isTopBtn.value = sT > 500 ? true : false
    if (sT >= arr[0] && sT < arr[1]) {
      tabsIndex.value = 0
    } else if (sT >= arr[1] && sT < arr[2]) {
      tabsIndex.value = 1
    } else if (sT >= arr[2] && sT < arr[3]) {
      tabsIndex.value = 2
    } else if (sT >= arr[3] && sT < arr[4]) {
      tabsIndex.value = 3
    } else if (sT >= arr[4] && sT < arr[5]) {
      tabsIndex.value = 4
    } else if (sT >= arr[5] && sT < arr[6]) {
      tabsIndex.value = 5
    } else if (sT >= arr[6] && sT < arr[7]) {
      tabsIndex.value = 6
    } else {
      tabsIndex.value = 0
    }
  }
}

// 返回层次递归数组
function sectionScrollHeight() {
  let arr = store['sectionArrs']
  let brr = [0]
  let count = 0
  let i = 0
  do {
    count += arr[i]
    brr.push(count)
    i++
  } while (i < arr.length)

  return brr
}

// 导航点击事件
function tabsChang(val) {
  clearTimeout(timer)
  if (!store['indexPage']) {
    proxy.$openFrame('/')
  }

  isScroll.value = false
  tabsIndex.value = val
  let arr = sectionScrollHeight()
  elscrollbar.value.scrollTo({
    left: 0,
    top: arr[val] || 0,
    behavior: 'smooth'
  })

  // 为了避免监测滚动条的事件造成 导航 active对象乱跳，使用时间函数控制延迟监控事件
  timer = setTimeout(function () {
    isScroll.value = true
  }, 1000)
}

// 点击返回顶部
function scrollToHeader() {
  elscrollbar.value.scrollTo({
    left: 0,
    top: 0,
    behavior: 'smooth'
  })
}
</script>

<style>
#app {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  color: var(--el-text-color-primary);
}

.sticky {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: 10px;
  bottom: 10px;
  width: 50px;
  z-index: 9;
}

.sticky .top {
  width: 50px;
  height: 50px;
  font-size: 30px;
}

/* 动画属性介绍

animation-duration  规定动画完成一个周期所花费的秒或毫秒
animation-delay   	规定动画何时开始。默认是 0
animation-iteration-count  规定动画被播放的次数。默认是 1
animation-timing-function  规定动画的速度曲线
      linear 	      动画从头到尾的速度是相同的
      ease          默认。动画以低速开始，然后加快，在结束前变慢
      ease-in       动画以低速开始
      ease-out 	    动画以低速结束
      ease-in-out 	动画以低速开始和结束
animation-fill-mode  规定当动画不播放时（当动画完成时，或当动画有一个延迟未开始播放时），要应用到元素的样式
      none 默认值。动画在动画执行之前和之后不会应用任何样式到目标元素。
      forwards  在动画结束后（由 animation-iteration-count 决定），动画将应用该属性值。
      backwards 动画将应用在 animation-delay 定义期间启动动画的第一次迭代的关键帧中定义的属性值。这些都是 from 关键帧中的值（当 animation-direction 为 "normal" 或 "alternate" 时）或 to 关键帧中的值（当 animation-direction 为 "reverse" 或 "alternate-reverse" 时）。
      both 动画遵循 forwards 和 backwards 的规则。也就是说，动画会在两个方向上扩展动画属性。
*/
[data-ani] {
  opacity: 0;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

.indown {
  animation-name: inDown;
}

.inUp {
  animation-name: inUp;
}

.inRight {
  animation-name: inRight;
}

.inShow {
  animation-name: inShow;
}

.inreverse {
  animation-direction: reverse;
}

/* 自定义动画 */
/* 上到下显示 */
@keyframes inDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 下到上显示 */
@keyframes inUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 左到右显示 */
@keyframes inRight {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* 右到左显示 */
@keyframes inLeft {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}


/* 渐显 */
@keyframes inShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
