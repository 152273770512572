import axios from 'axios'
import qs from 'qs'
import { ElMessage, ElLoading } from 'element-plus'

import router from '@/router/index'

let loadingInstance = null // loadding动画变量

const service = axios.create({
  // baseURL: '/api', // 公共接口
  timeout: 3000,  // 最大请求时间设置
  headers: {
    'token': 'wangchun',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

//请求拦截器 请求之前的操作
service.interceptors.request.use(
  async config => {
    // 如果有需要在这里开启请求时的loadding动画效果
    loadingInstance = ElLoading.service({
      lock: true,
      text: '请求访问中…',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })

    // config.headers.Authorization = 'wangchun' // 添加token，需要结合自己的实际情况添加
    // 每次发送请求之前判断vuex中是否存在token
    // config.headers.token = sessionStorage.getItem('token')
    return config;
  },
  error => {
    return Promise.error(error);
  })

// 响应拦截器 响应之后的操作
service.interceptors.response.use(
  response => {
    // 在这里关闭请求时的loadding动画效果
    loadingInstance.close()

    if (response.status === 200) {
      return Promise.resolve(response); //进行中        
    } else {
      ElMessage.error('访问失败！')
      return Promise.reject(response); //失败
    }
  },
  // 服务器状态码不是200的情况    
  error => {
    // 在这里关闭请求时的loadding动画效果
    loadingInstance.close()

    if (error && error.response) {
      switch (error.response.status) {
        // 401: 未登录                
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作
        case 401:
          error.message = '请重新登录[401]'
          $openFrame('/')
          break
        // 403 token过期
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          error.message = '拒绝访问[403]'
          // sessionStorage.clear()
          $openFrame('/')
          break
        case 404:
          error.message = '请求出错[404]'
          break;
        case 500:
          error.message = '服务器错误[500]'
          break;
        // 其他错误，直接抛出错误提示
        default:
          error.message = '连接错误[' + error.response.status + ']'
      }

      ElMessage.error(error.message)
    } else {
      // 超时处理
      if (JSON.stringify(error).includes('timeout')) {
        ElMessage.error('服务器响应超时：' + error.message)
      }
    }
    return Promise.reject(error.response);
  }
)

/** 
* get方法，对应get请求 
* @param {String} url [请求的url地址] 
* @param {Object} params [请求时携带的参数] 
*/
const $get = (url, params) => {
  return new Promise((resolve, reject) => {
    service.get(url, { params: params }).then(res => { resolve(res.data) }).catch(err => { reject(err) })
  })
}

/** 
* post方法，对应post请求 
* @param {String} url [请求的url地址] 
* @param {Object} params [请求时携带的参数] 
*/
const $post = (url, params) => {
  return new Promise((resolve, reject) => {
    //是将对象 序列化成URL的形式，以&进行拼接
    service.post(url, qs.stringify(params)).then(res => { resolve(res.data); }).catch(err => { reject(err) })
  })
}

/**
* 获取浏览器地址 端口和域名
*/
const $getURL = () => {
  const protocol = window.location.protocol // http:|| https:
  const hostname = window.location.hostname // 域名
  const port = window.location.port // 端口
  let Url = protocol + '//' + hostname + ':' + port

  if (port === '80' && port === '443') {
    Url = protocol + '//' + hostname
  }
  return Url
}

// 跳转页面
const $openFrame = (path, query) => {
  try {
    if (query) {
      // path 不能用params传参 因为params需要name
      router.push({ path: path, query: query })
    } else {
      router.push({ path: path })
    }
  }
  catch (err) { ElMessage.error('无效路由!'); return false }
}


// 获取页面传参
const $getQuery = (pathName) => {
  let query_val = router.currentRoute.value.query
  return query_val[pathName]
}

/**********************************************************************************************************************************
 * 获取当前时间（年月日时分秒）格式 
 * $getCurrentDate('YY') // 2022
 * $getCurrentDate('YY-MM')	// 2022-06
 * $getCurrentDate('YY-MM-DD')	// 2022-06-02
 * $getCurrentDate('YY-MM-DD hh:mm:ss')	// 2022-06-02 10:02:23
 * $getCurrentDate('星期W')	// 星期四
 **********************************************************************************************************************************/
const $getCurrentDate = (fmt) => {
  const date = new Date()
  const o = {
    'Y+': date.getFullYear(),
    'M+': date.getMonth() + 1, // 月
    'D+': date.getDate(), // 日
    'h+': date.getHours(), // 时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    W: date.getDay() // 周
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, () => {
        if (k === 'W') {
          // 星期几
          const week = ['日', '一', '二', '三', '四', '五', '六']
          return week[o[k]]
        } else if (k === 'Y+' || RegExp.$1.length === 1) {
          // 年份 or 小于10不加0
          return o[k]
        } else {
          return ('00' + o[k]).substr(('' + o[k]).length) // 小于10补位0
        }
      })
    }
  }
  return fmt
}

// 加载动画
const $loadAnimaet = (name) => {
  let dom = document.querySelectorAll('.' + name)
  dom.forEach((v) => {
    if (v.dataset.dir) {
      v.classList.add(v.dataset.dir)
    }
    var delay = v.dataset.delay
    v.style.animationDelay = delay + 's'
    v.classList.add(v.dataset.ani)
  })
}

// 卸载动画
const $romoveAnimaet = (name) => {
  let dom = document.querySelectorAll('.' + name)
  dom.forEach((v) => {
    v.style.animationDelay = ''
    v.classList.remove(v.dataset.ani, v.dataset.dir)
  })
}

// 滚动动画
const $scrollAnimate = (sT) => {
  let dom = document.querySelectorAll('.wcani')
  let wH = window.innerHeight
  dom.forEach((v) => {
    if (sT + wH > v.offsetTop) {
      let delay = v.dataset.delay
      v.style.animationDelay = delay + 's'
      v.classList.add(v.dataset.ani)
    } else {
      v.style.animationDelay = ''
      v.classList.remove(v.dataset.ani)
    }
  })
}

export default {
  install: (app) => {
    app.config.globalProperties['$getURL'] = $getURL
    app.config.globalProperties['$get'] = $get
    app.config.globalProperties['$post'] = $post
    app.config.globalProperties['$openFrame'] = $openFrame
    app.config.globalProperties['$getCurrentDate'] = $getCurrentDate
    app.config.globalProperties['$getQuery'] = $getQuery
    app.config.globalProperties['$loadAnimaet'] = $loadAnimaet
    app.config.globalProperties['$romoveAnimaet'] = $romoveAnimaet
    app.config.globalProperties['$scrollAnimate'] = $scrollAnimate
  }
}